import QuoteQueryJets from '../components/QuoteQueryJets'
import {useContext, useEffect, useRef, useState} from 'react'
import QuoteQueryHelicopters from '../components/QuoteQueryHelicopters'
import Button from '../components/Button'
import Modal from '../components/Modal'
import SetNewPassword from './SetNewPassword'
import QuoteQueryGroupCharter from '../components/QuoteQueryGroupCharter'
import AircraftBrowser from '../components/AircraftBrowser'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import {Parallax} from 'react-scroll-parallax'
import getClassName from '../utilities/getClassName'
import Image from '../components/Image'
import SEO from '../components/SEO'
import NewsletterForm from '../components/NewsletterForm'
import parse from 'html-react-parser'
import {useGetCacheContent} from '../hooks/useGetCacheContent'
import LoadingOverlay from '../components/LoadingOverlay'
import poweredByContext from '../store/PoweredByContext'
import {replaceShyName} from '../utilities/string'
import {getIcon, getRawIcon} from '../utilities/icon'
import {isPelorus} from '../utilities/poweredBy'
import {getUrl} from '../utilities/url'

// Icons
import speechTLWhite from '../assets/icons/speech-marks-top-left-white.svg'
import speechBRWhite from '../assets/icons/speech-marks-bottom-right-white.svg'
import speechTLGold from '../assets/icons/speech-marks-top-left-gold.svg'
import speechBRGold from '../assets/icons/speech-marks-bottom-right-gold.svg'
import shyLifestyleLogo from '../assets/icons/shy-lifestyle-logo.svg'
 
const Home = ({showPasswordReset}) => {

    // State
    const [searchType, setSearchType] = useState('jets')
    const [passwordReset, setPasswordReset] = useState(showPasswordReset)
    const [closeContactWidget, setCloseContactWidget] = useState(false)
    const [animate, setAnimate] = useState([])
    const [scrollTop, setScrollTop] = useState(0)
    const [foldIndicatorOpacity, setFoldIndicatorOpacity] = useState(1)

    const poweredByCtx = useContext(poweredByContext)
    const foldTarget = useRef()

    // Get and cache content
    const {content: homeContent} = useGetCacheContent('homeContent', '/api/content/home', 400)

    // Hooks
    const location = useLocation()

    let homeIcons

    const scrollToTopHandler = () => {
        window.scrollTo(0, 0)
    }

    const startJetsQuoteHandler = () => {
        setSearchType('jets')
        window.scrollTo(0, 0)
    }

    // const startHelicopterQuoteHandler = () => {
    //     setSearchType('helicopters')
    //     window.scrollTo(0, 0)
    // }
    //
    // const startGroupCharterQuoteHandler = () => {
    //     setSearchType('group-charter')
    //     window.scrollTo(0, 0)
    // }

    useEffect(() => {
        const handleScroll = (event) => {
            setScrollTop(window.scrollY)
        };

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        if (scrollTop >= 100) {
            setFoldIndicatorOpacity(0)
        } else {
            setFoldIndicatorOpacity(1)
        }
    }, [scrollTop])

    // Animate In Out with parallax
    const animateInOutHandler = (progress, percent, animRef) => {
        if (progress > percent && !animate[animRef]) {
            const updateAnim = {...animate, [animRef]: true}
            setAnimate(updateAnim)
        }

        if (progress === 0 && animate[animRef]) {
            const updateAnim = {...animate, [animRef]: false}
            setAnimate(updateAnim)
        }
    }

    const scrollToFold = () => {
        if (foldTarget.current) {
            const main = document.querySelector('.main')
            const target = foldTarget.current.getBoundingClientRect().top
            let extra = 0

            if (main) {
                extra += parseInt(getComputedStyle(main).paddingTop)
            }
            
            window.scrollTo(0, target - extra)
        }
    }

    const replaceName = (str) => {
        return replaceShyName(str, poweredByCtx.isPoweredBy, poweredByCtx?.tenant?.tenantName)
    }

    const getPhoneNumber = () => {       
        if (poweredByCtx && poweredByCtx.isPoweredBy && poweredByCtx?.tenant.tenantPhoneNumber) {
            return poweredByCtx.tenant.tenantPhoneNumber.split(';').map(number => number.trim());
        }
        return [homeContent?.getInTouch?.phoneNumber];
    };
    

    const getEmailAddress = () => {
        if (poweredByCtx.isPoweredBy && poweredByCtx?.tenant.tenantEmailAddress) {
            return poweredByCtx.tenant.tenantEmailAddress
        }
        return homeContent.getInTouch.emailAddress
    }

    const getBackgroundImage = () => {
        let image

        if (poweredByCtx?.tenant.heroImage) {
            image = poweredByCtx.tenant.heroImage
        } else {
            console.log(`Hero image not available for ${poweredByCtx.tenantID}`)
        }
        
        if (image) {
            return image
        }
    }

    if (poweredByCtx.isPoweredBy) {
        homeIcons = [
            getRawIcon('home-carbon-emissions'),
            getRawIcon('home-globe'),
            getRawIcon('home-lock'),
            getRawIcon('home-time')
        ]
    }

    return (
        <>
            {homeContent &&
                <SEO
                    title={homeContent.metaTitle}
                    description={homeContent.metaDescription}
                    canonicalUrl={getUrl(poweredByCtx, '/')}
                    isHomepage
                />
            }
            
            {(!homeContent || !poweredByCtx.siteReady) ? <LoadingOverlay clear /> :
                <>
                    <div className='brochure home'>

                        <section className='home__query'>
                            {poweredByCtx.isPoweredBy &&
                                <figure className='home__query-background-image'>
                                    <img src={getBackgroundImage()} alt='' />
                                </figure>
                            }
                            <div className='home__query__content wrap'>
                                <h1 className='home__query__title'>Welcome Aboard</h1>
                                <h2 className='home__query__sub-title'>A Luxury aviation experience, tailored to you</h2>

                                <div className='home__query__aircraft-type-container'>
                                    <div className='home__query__aircraft-type-container__title'>
                                        {(searchType === 'jets') ? 'Private Jets' : null}
                                        {(searchType === 'helicopters') ? 'Helicopters' : null}
                                        {(searchType === 'group-charter') ? 'Group Charter' : null}
                                    </div>

                                    {(searchType === 'helicopters' || searchType === 'group-charter') ?
                                        <div className='home__query__aircraft-type-container__back-link'>Back to <button onClick={() => setSearchType('jets')}>Private Jets</button></div>
                                        : null}
                                </div>

                                {(searchType === 'jets') ? <QuoteQueryJets onFocus={setCloseContactWidget.bind(this, true)} /> : null}
                                {(searchType === 'helicopters') ? <QuoteQueryHelicopters /> : null}
                                {(searchType === 'group-charter') ? <QuoteQueryGroupCharter /> : null}

                                <div className='home__query__are-you-looking-for'>
                                    <p>Are you looking for</p><span className='home__query__are-you-looking-for__comma'> </span>
                                    {(searchType !== 'jets') && <>
                                        <button onClick={() => setSearchType('jets')}>Private Jets</button><span className='home__query__are-you-looking-for__comma'>, </span>
                                    </>}
                                    {(searchType !== 'helicopters') &&
                                        <>
                                            <Link to='/fly/helicopters'>Helicopters</Link><span className='home__query__are-you-looking-for__comma'>, </span>
                                        </>
                                    }

                                    {(searchType !== 'group-charter') && <>
                                        <>
                                            <Link to='fly/group-charter'>Group Charter</Link><span className='home__query__are-you-looking-for__comma'>, </span>
                                        </>
                                    </>}

                                    ?
                                </div>

                            </div>

                            <div className='fold-indicator' style={{opacity: foldIndicatorOpacity}} onClick={scrollToFold}>
                                <button className='fold-indicator__content'>
                                    {poweredByCtx.isPoweredBy
                                        ?
                                            <>
                                                {getRawIcon('fold-arrow')}
                                                {getRawIcon('fold-arrow')}
                                            </>
                                        :
                                            <>
                                                <img src={getIcon('fold-arrow')} alt='' />
                                                <img src={getIcon('fold-arrow')} alt='' />
                                            </>
                                    }
                                </button>
                            </div>

                        </section>

                        {!isPelorus(poweredByCtx) &&
                            <section className='home__why-shy wrap' ref={foldTarget}>

                                {/* Title 1 */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'title1')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.title1 && 'fade-and-travel--visible'])}
                                >
                                    <h2>Trust us to get you there</h2>
                                    <h1>{replaceName('Why Shy Aviation')}</h1>
                                </Parallax>

                                {/* Home Panels 1 */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'homePanel1')
                                    }}
                                    className={getClassName(['brochure__panels', 'home-panels-anim', animate.homePanel1 && 'home-panels-anim--visible'])}
                                >
                                    {homeContent.whyShyAviation.map((card, i) => {
                                        return (
                                            <Parallax
                                                onProgressChange={(progress) => {animateInOutHandler(progress, 0.15, `whyShyAviation${i}`)}}
                                                className={getClassName(['brochure__panel', 'fade-and-travel--mobile', 'fade-and-travel', animate[`whyShyAviation${i}`] && 'fade-and-travel--visible'])}
                                                key={`whyShyAviation${i}`}
                                            >
                                                {poweredByCtx.isPoweredBy
                                                    ?
                                                        <div className='brochure__panel__icon'>
                                                            {homeIcons[i]}
                                                        </div>
                                                    :
                                                        <img alt={card.imageHolder.alt} className='brochure__panel__icon' src={card.imageHolder.url} />
                                                }
                                                <div className='brochure__panel__title'>
                                                    {card.title}
                                                </div>
                                                <div className='brochure__panel__body'>
                                                    {replaceName(card.body)}
                                                </div>
                                            </Parallax>
                                        )
                                    })}
                                </Parallax>

                                {/* Button 1 */}
                                <Parallax
                                    opacity={[0, 4]}
                                    className='brochure__button-wrapper'
                                >
                                    <Button onClick={scrollToTopHandler} classNames={['button--full', poweredByCtx.isPoweredBy ? undefined : 'button--tertiary']} animate={true}>Start a Quote</Button>
                                </Parallax>

                            </section>
                        }

                        {!isPelorus(poweredByCtx) &&
                            <section className='home__services wrap'>

                                {/* Title 2 */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'title2')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.title2 && 'fade-and-travel--visible'])}
                                >
                                    <h2>Continue Your Extraordinary Journey</h2>
                                    <h1>Services</h1>
                                </Parallax>

                                {/* Services Panels */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'servicesPanels')
                                    }}
                                    className={getClassName(['home__services__grid', 'home-panels-anim', animate.servicesPanels && 'home-panels-anim--visible'])}
                                >
                                    <Parallax
                                        onProgressChange={(progress) => {animateInOutHandler(progress, 0.15, 'service1')}}
                                        className={getClassName(['home_service', 'fade-and-travel--mobile', 'fade-and-travel', animate.service1 && 'fade-and-travel--visible'])}
                                    >
                                        <div className='home_service__photo'>
                                            <Image fillParent showLoading src={homeContent.services.privateJets.imageHolder.url} alt={homeContent.services.privateJets.imageHolder.alt}/>
                                        </div>
                                        <div className='home_service__detail'>
                                            <div className='home_service__title'>Private Jets</div>
                                            <div className='home_service__body'>
                                                {replaceName(homeContent.services.privateJets.body)}
                                            </div>
                                            <div className='home_service__ctas'>
                                                <button onClick={startJetsQuoteHandler}>Start a Quote</button>
                                                <Link to='/fly/private-jets'>Explore</Link>                                            
                                            </div>
                                        </div>
                                    </Parallax>

                                    <Parallax
                                        onProgressChange={(progress) => {animateInOutHandler(progress, 0.15, 'service2')}}
                                        className={getClassName(['home_service', 'fade-and-travel--mobile', 'fade-and-travel', animate.service2 && 'fade-and-travel--visible'])}
                                    >
                                        <div className='home_service__photo'>
                                            <Image fillParent showLoading src={homeContent.services.helicopter.imageHolder.url} alt={homeContent.services.helicopter.imageHolder.alt}/>
                                        </div>
                                        <div className='home_service__detail'>
                                            <div className='home_service__title'>Helicopters</div>
                                            <div className='home_service__body'>
                                                {replaceName(homeContent.services.helicopter.body)}
                                            </div>
                                            <div className='home_service__ctas'>
                                                {/*<button onClick={startHelicopterQuoteHandler}>Start a Quote</button>*/}
                                                <Link to='/fly/helicopters'>Learn more</Link>
                                            </div>
                                        </div>
                                    </Parallax>

                                    <Parallax
                                        onProgressChange={(progress) => {animateInOutHandler(progress, 0.15, 'service3')}}
                                        className={getClassName(['home_service', 'fade-and-travel--mobile', 'fade-and-travel', animate.service3 && 'fade-and-travel--visible'])}
                                    >
                                        <div className='home_service__photo'>
                                            <Image fillParent showLoading src={homeContent.services.groupCharter.imageHolder.url} alt={homeContent.services.groupCharter.imageHolder.alt} />
                                        </div>
                                        <div className='home_service__detail'>
                                            <div className='home_service__title'>Group Charter</div>
                                            <div className='home_service__body'>
                                                {replaceName(homeContent.services.groupCharter.body)}
                                            </div>
                                            <div className='home_service__ctas'>
                                                {/*<button onClick={startGroupCharterQuoteHandler}>Start a Quote</button>*/}
                                                <Link to='/fly/group-charter'>Learn more</Link>
                                            </div>
                                        </div>
                                    </Parallax>
                                </Parallax>

                                {/* Button 2 */}
                                <Parallax
                                    opacity={[0, 4]}
                                    className='brochure__button-wrapper'
                                >
                                    <Button onClick={scrollToTopHandler} classNames={['button--full', poweredByCtx.isPoweredBy ? undefined : 'button--tertiary']} animate={true}>Start a Quote</Button>
                                </Parallax>

                            </section>
                        }

                        {/* Anchor point to scroll to */}
                        <div id='private-jets' style={{transform: 'translate(0, -100px)'}} />
                        <section className='home__aircraft'>

                            {/* Title 3 */}
                            <Parallax
                                onProgressChange={(progress) => {
                                    animateInOutHandler(progress, 0.15, 'title3')
                                }}
                                className={getClassName(['fade-and-travel', animate.title3 && 'fade-and-travel--visible'])}
                            >
                                <div className='wrap'>
                                    <h2>{homeContent?.headerSection?.subHeader}</h2>
                                    <h1>{homeContent?.headerSection?.header}</h1>
                                </div>
                            </Parallax>

                            <Parallax
                                onProgressChange={(progress) => {
                                    animateInOutHandler(progress, 0.15, 'aircraftBrowser')
                                }}
                                className={getClassName(['fade-and-travel', animate.aircraftBrowser && 'fade-and-travel--visible'])}
                                style={{maxWidth: '100vw'}}
                            >
                                <AircraftBrowser />
                            </Parallax>

                            <Parallax
                                opacity={[0, 4]}
                                className='brochure__button-wrapper wrap'
                            >
                                <Button onClick={scrollToTopHandler} classNames={['button--full', poweredByCtx.isPoweredBy ? undefined : 'button--tertiary']} animate={true}>Start a Quote</Button>
                            </Parallax>
                        </section>

                        {!poweredByCtx.isPoweredBy &&
                            <section className='home__quotes wrap'>
                                {/* Quotes */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'quotePanels')
                                    }}
                                    className={getClassName(['home__quotes__grid', 'home-panels-anim', animate.quotePanels && 'home-panels-anim--visible'])}
                                >

                                    {homeContent.testimonials.map((testimonial, i) => {
                                        return (
                                            <Parallax
                                                onProgressChange={(progress) => {animateInOutHandler(progress, 0.15, `testimonial${i}`)}}
                                                className={getClassName(['home__quote', 'fade-and-travel--mobile', 'fade-and-travel', animate[`testimonial${i}`] && 'fade-and-travel--visible'])}
                                                key={`testimonial${i}`}
                                            >
                                                <div className='home__quote__text'>
                                                    <img src={speechTLGold} className='home__quote__text__speech-mark' alt='' />
                                                    <img src={speechBRGold} className='home__quote__text__speech-mark home__quote__text__speech-mark--bottom-right' alt='' />
                                                    <img src={speechTLWhite} className='home__quote__text__speech-mark home__quote__text__speech-mark--white' alt='' />
                                                    <img src={speechBRWhite} className='home__quote__text__speech-mark home__quote__text__speech-mark--white home__quote__text__speech-mark--bottom-right' alt='' />
                                                    {testimonial.testimonial}
                                                </div>
                                                <div className='home__quote__by'>
                                                    {testimonial.testimonialBy}
                                                </div>
                                            </Parallax>
                                        )
                                    })}
                                </Parallax>
                            </section>
                        }

                        {!poweredByCtx.isPoweredBy &&
                            <section className='home__shy-lifestyle wrap'>

                                {/* Title 5 */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'title5')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.title5 && 'fade-and-travel--visible'])}
                                >
                                    <h2>{homeContent.shyLifestyle.subTitle}</h2>
                                    <h1>{homeContent.shyLifestyle.title}</h1>
                                </Parallax>

                                {/* Lifestyle */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'foundation')
                                    }}
                                    className={getClassName(['brochure__2-column-layout', 'fade-and-travel', animate.foundation && 'fade-and-travel--visible'])}
                                >
                                    <div className='brochure__2-column-layout__text'>
                                        <img className='home__shy-lifestyle__logo' src={shyLifestyleLogo} alt='Shy Lifestyle Logo' />
                                        {parse(homeContent.shyLifestyle.body)}
                                        <Parallax
                                            opacity={[0, 4]}
                                            className='brochure__button-wrapper home__shy-lifestyle__button-wrapper'
                                        >
                                            <a target='_blank' rel='noreferrer' href='https://www.shylifestyle.com/'><Button classNames={['button--full', poweredByCtx.isPoweredBy ? undefined : 'button--tertiary']} animate={true}>Explore Shy Lifestyle</Button></a>
                                        </Parallax>
                                    </div>

                                    <div className='brochure__2-column-layout__image'>
                                        <Image fillParent showLoading src={homeContent.shyLifestyle.imageHolder.url} alt={homeContent.shyLifestyle.imageHolder.alt}/>
                                    </div>
                                </Parallax>
                            </section>
                        }
                    
                        {!isPelorus(poweredByCtx) &&
                            <section className='home__safety wrap'>

                                {/* Title 6 */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'title6')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.title6 && 'fade-and-travel--visible'])}
                                >
                                    <h2>{homeContent.safety.subTitle}</h2>
                                    <h1>{homeContent.safety.title}</h1>
                                </Parallax>

                                {/* Safety 5 */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'safety')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.safety && 'fade-and-travel--visible'])}
                                >
                                    <div className='brochure__centered-text'>
                                        {parse(replaceName(homeContent.safety.body))}
                                        {/* Button 5 */}
                                        <Parallax
                                            opacity={[0, 4]}
                                            className='brochure__button-wrapper'
                                        >
                                            <Button onClick={scrollToTopHandler} classNames={['button--full', poweredByCtx.isPoweredBy ? undefined : 'button--tertiary']} animate={true}>Start a Quote</Button>
                                        </Parallax>

                                    </div>
                                </Parallax>
                            </section>
                        }

                        <section className='home__contact wrap'>

                            {/* Title 7 */}
                            <Parallax
                                onProgressChange={(progress) => {
                                    animateInOutHandler(progress, 0.15, 'title7')
                                }}
                                className={getClassName(['fade-and-travel', animate.title7 && 'fade-and-travel--visible'])}
                            >
                                <h2>We are always happy to help</h2>
                                <h1>Get In Touch</h1>
                            </Parallax>

                            <Parallax
                                onProgressChange={(progress) => {
                                    animateInOutHandler(progress, 0.15, 'homePanel2')
                                }}
                                className={getClassName(['brochure__panels', 'home__panels--contact', 'home-panels-anim', animate.homePanel2 && 'home-panels-anim--visible'])}
                            >
                                <Parallax
                                    onProgressChange={(progress) => {animateInOutHandler(progress, 0.15, 'panel5')}}
                                    className={getClassName(['brochure__panel', 'fade-and-travel--mobile', 'fade-and-travel', animate.panel5 && 'fade-and-travel--visible'])}
                                >
                                    {poweredByCtx.isPoweredBy
                                        ?
                                            <div className='brochure__panel__icon'>
                                                {getRawIcon('home-phone')}
                                            </div>
                                        :
                                            <img alt='Call us on the telephone' className='brochure__panel__icon' src={getIcon('home-phone')} />
                                    }
                                    <div className='brochure__panel__title'>
                                        Call Us
                                    </div>
                                    <div className='brochure__panel__body'>
                                        {getPhoneNumber().map((phoneNumber, i) => {
                                            return <div key={i}>
                                                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                                            </div>
                                        })}
                                    </div>
                                </Parallax>

                                <Parallax
                                    onProgressChange={(progress) => {animateInOutHandler(progress, 0.15, 'panel6')}}
                                    className={getClassName(['brochure__panel', 'fade-and-travel--mobile', 'fade-and-travel', animate.panel6 && 'fade-and-travel--visible'])}
                                >
                                    {poweredByCtx.isPoweredBy
                                        ?
                                            <div className='brochure__panel__icon'>
                                                {getRawIcon('home-email')}
                                            </div>
                                        :
                                            <img alt='Email us' className='brochure__panel__icon' src={getIcon('home-email')} />
                                    }
                                    <div className='brochure__panel__title'>
                                        Email Us
                                    </div>
                                    <div className='brochure__panel__body'>
                                        <a href={`mailto:${getEmailAddress()}`}>{getEmailAddress()}</a>
                                    </div>
                                </Parallax>

                                {!poweredByCtx.isPoweredBy &&
                                    <Parallax
                                        onProgressChange={(progress) => {animateInOutHandler(progress, 0.15, 'panel7')}}
                                        className={getClassName(['brochure__panel', 'fade-and-travel--mobile', 'fade-and-travel', animate.panel7 && 'fade-and-travel--visible'])}
                                    >
                                        <img alt='' className='brochure__panel__icon' src={getIcon('home-location')} />
                                        <div className='brochure__panel__title'>
                                            Find Us
                                        </div>
                                        <div className='brochure__panel__body'>
                                            <a target='_blank' rel='noreferrer' href={homeContent.getInTouch.mapsLink}>
                                                {homeContent.getInTouch.streetAddress}
                                            </a>
                                        </div>
                                    </Parallax>
                                }
                            </Parallax>

                        </section>
                        
                        {/* <ContactWidgetAutoPopup forceClose={closeContactWidget} /> */}

                        <Modal
                            classNames={['modal__content--thin']}
                            modalVisible={passwordReset}
                            closeModal={() => setPasswordReset(false)}
                        >
                            <SetNewPassword closeModal={() => setPasswordReset(false)} />
                        </Modal>
                    </div>
                    <NewsletterForm />
                </>
            }
        </>
    )
}

export default Home