import bitPay from '../assets/icons/bitpay.svg'
import logo from '../assets/icons/shy-logo.svg'
import poweredbylogo from '../assets/icons/shy-logo-powered-by-logo.svg'
import Modal from './Modal'
import useModal from '../hooks/useModal'
import CarbonNeutral from './CarbonNeutral'
import {Link} from 'react-router-dom'
import {useContext, useEffect, useState} from 'react'
import useGet from '../hooks/useGet'
import {getRestrictedLinks} from '../utilities/settings'
import {getIcon, getRawIcon} from '../utilities/icon'
import {isPelorus} from '../utilities/poweredBy'
import PoweredByContext from '../store/PoweredByContext'

const Footer = () => {

    // State
    const [showCookieSettingsButton, setShowCookieSettingsButton] = useState(false)
    const [links, setLinks] = useState(false)

    // Hooks
    const {modalVisible, closeModal, openModal} = useModal()
    const {returnData} = useGet('/api/content/footer')
    const poweredByCtx = useContext(PoweredByContext)

    useEffect(() => {
        if (returnData && poweredByCtx.siteReady) {
            const restrictedLinks = getRestrictedLinks(poweredByCtx.isPoweredBy)
            const filteredData = returnData.filter((link) => {
                let valid = true

                if (restrictedLinks.length > 0) {
                    restrictedLinks.forEach((restrictedLink) => {
                        if (link.url.indexOf(restrictedLink) > -1) {
                            valid = false
                        }
                    })
                }

                return valid
            })

            if (isPelorus(poweredByCtx)) {
                filteredData.unshift({
                    name: poweredByCtx?.tenant?.tenantName,
                    url: 'https://pelorusaviation.com'
                })
            }

            if (filteredData.length > 4) {
                const linksLength = filteredData.length / 2

                setLinks([
                    filteredData.slice(0, linksLength),
                    filteredData.slice(linksLength),
                ])
            } else {
                setLinks([
                    filteredData,
                ])
            }
        }
    }, [returnData, poweredByCtx.isPoweredBy, poweredByCtx.siteReady])

    // Set year
    const year = new Date().getFullYear();

    // Check chat is actually available on the page
    setTimeout(() => {
        if (document.getElementsByClassName('cky-btn-revisit').length) {
            setShowCookieSettingsButton(true)
        }
    }, 2500)

    // Click the hidden cookie button on behalf of user
    const cookieSettingsClick = () => {
        var btnRevisit = document.querySelector('.cky-btn-revisit');
        if (btnRevisit) {
            btnRevisit.click();
        }
    }

    const isExternalLink = (url) => {
        return url.indexOf('http') === 0
    }

    const getSocialLink = (service) => {
        const links = {
            instagram: 'https://www.instagram.com/shyaviation/?hl=en-gb',
            facebook: 'https://www.facebook.com/shyaviation/',
            twitter: 'https://twitter.com/shyaviation',
            tiktok: 'https://www.tiktok.com/@shyaviation',
            linkedin: 'https://www.linkedin.com/company/shy-aviation',
        };
    
        if (service) {
            if (poweredByCtx.isPoweredBy) {
                const tenantLinks = {
                    instagram: poweredByCtx.tenant?.instagram,
                    facebook: poweredByCtx.tenant?.facebook,
                    twitter: poweredByCtx.tenant?.twitter,
                    tiktok: poweredByCtx.tenant?.tiktok,
                    linkedin: poweredByCtx.tenant?.linkedin,
                };
    
                return tenantLinks[service] || null;
            } else if (links[service]) {
                return links[service];
            }
        }
        return null;
    };

    const showLogo = () => {
        if (poweredByCtx.isPoweredBy) {
            return poweredByCtx.tenant.showPoweredByLogo
        }
        return true
    }

    const hasCarbonNeutralLink = () => {
        if (poweredByCtx.isPoweredBy) {
            return poweredByCtx.tenant.tenantCarbonNeutralUrl !== undefined
        }
        return false
    }

    const getCarbonNeutralLink = () => {
        if (poweredByCtx.isPoweredBy) {
            return poweredByCtx.tenant.tenantCarbonNeutralUrl
        }
    }

    return (
        <>
            <footer className='footer'>
                <div className='wrap'>

                    {showLogo() &&
                        <Link to='/'>
                            {poweredByCtx.isPoweredBy
                                ?
                                    <figure className='footer__powered-by-logo'>
                                        <img src={poweredbylogo} alt='SHY Logo' />
                                    </figure>
                                :
                                    <img
                                        className='footer__logo'
                                        src={logo}
                                        alt='SHY Logo'
                                    />
                            }
                        </Link>
                    }

                    <div className='footer__socials'>
                        {getSocialLink('instagram') &&
                            <a href={getSocialLink('instagram')} rel='noreferrer' target='_blank'>
                                {poweredByCtx.isPoweredBy
                                    ?
                                        <>
                                            {getRawIcon('footer-instagram')}
                                        </>
                                    :
                                        <>
                                            <img src={getIcon('footer-instagram')} alt='' />
                                            <img src={getIcon('footer-instagram-hover')} alt='' />
                                        </>
                                }
                                Instagram
                            </a>
                        }
                        {getSocialLink('facebook') &&
                            <a href={getSocialLink('facebook')} rel='noreferrer' target='_blank'>
                                {poweredByCtx.isPoweredBy
                                    ?
                                        <>
                                            {getRawIcon('footer-facebook')}
                                        </>
                                    :
                                        <>
                                            <img src={getIcon('footer-facebook')} alt='' />
                                            <img src={getIcon('footer-facebook-hover')} alt='' />
                                        </>
                                }
                                Facebook
                            </a>
                        }
                        {getSocialLink('twitter') &&
                            <a href={getSocialLink('twitter')} rel='noreferrer' target='_blank'>
                                {poweredByCtx.isPoweredBy
                                    ?
                                        <>
                                            {getRawIcon('footer-twitter')}
                                        </>
                                    :
                                        <>
                                            <img src={getIcon('footer-twitter')} alt='' />
                                            <img src={getIcon('footer-twitter-hover')} alt='' />
                                        </>
                                }
                                Twitter
                            </a>
                        }
                        {getSocialLink('tiktok') &&
                            <a href={getSocialLink('tiktok')} rel='noreferrer' target='_blank'>
                                {poweredByCtx.isPoweredBy
                                    ?
                                        <>
                                            {getRawIcon('footer-tik-tok')}
                                        </>
                                    :
                                        <>
                                            <img src={getIcon('footer-tik-tok')} alt='' />
                                            <img src={getIcon('footer-tik-tok-hover')} alt='' />
                                        </>
                                }
                                TikTok
                            </a>
                        }
                        {getSocialLink('linkedin') &&
                            <a href={getSocialLink('linkedin')} rel='noreferrer' target='_blank'>
                                {poweredByCtx.isPoweredBy
                                    ?
                                        <>
                                            {getRawIcon('footer-linkedin')}
                                        </>
                                    :
                                        <>
                                            <img src={getIcon('footer-linkedin')} alt='' />
                                            <img src={getIcon('footer-linkedin-hover')} alt='' />
                                        </>
                                }
                                LinkedIn
                            </a>
                        }
                    </div>

                    {links &&
                        <div className='footer__links'>
                            {links.map((linkGroup, i) => {
                                return <div className='footer__links__column' key={i}>
                                    {linkGroup.map((link, i) => {
                                        if (link.name === 'Carbon Neutral') {
                                            if (hasCarbonNeutralLink()) {
                                                return <a href={getCarbonNeutralLink()} target='_blank' rel='noreferrer' key={i}>{link.name}</a>
                                            } else {
                                                return <button onClick={openModal} key={i}>{link.name}</button>
                                            }
                                        } else {
                                            if (isExternalLink(link.url)) {
                                                return <a href={link.url} target='_blank' rel='noreferrer' key={i}>{link.name}</a>
                                            } else {
                                                return <Link to={link.url} key={i}>{link.name}</Link>
                                            }
                                        }
                                    })}
                                </div>
                            })}
                        </div>
                    }

                    {!poweredByCtx.isPoweredBy &&
                        <div className='footer__lifestyle-info'>
                            <div className='footer__lifestyle-info__text'>
                                For luxury lifestyle, check out SHY Lifestyle <a rel='noreferrer' target='_blank' href='https://www.shylifestyle.com?utm_source=aviation&utm_medium=link&utm_campaign=homepage&utm_content=footer'>here</a>
                            </div>
                        </div>
                    }

                    <div className='footer__final-links'>
                        <img className='footer__final-links__bitpay' src={bitPay} alt='' />

                        <div className='footer__final-links__legal-info'>
                            <Link to='/terms-conditions'>Terms & Conditions</Link>
                            <div className='footer__final-links__legal-info__divide' />
                            <Link to='/privacy-policy'>Privacy Policy</Link>
                            {showCookieSettingsButton &&
                                <>
                                    <div className='footer__final-links__legal-info__divide' />
                                    <button onClick={cookieSettingsClick}>Cookie Settings</button>
                                </>
                            }
                        </div>

                        <div className='footer__final-links__copyright'>
                            © Copyright SHY Aviation {year}.
                        </div>
                    </div>

                </div>
            </footer>

            <Modal
                modalVisible={modalVisible}
                closeModal={closeModal}
            >
                <CarbonNeutral closeModal={closeModal} />
            </Modal>
        </>
    )
}

export default Footer