import {useContext, useEffect, useState} from 'react'
import useGet from '../hooks/useGet'
import BlogArticlePreview from '../components/BlogArticlePreview'
import Button from '../components/Button'
import Loading from '../components/Loading'
import SEO from '../components/SEO'
import NewsletterForm from '../components/NewsletterForm'
import poweredByContext from '../store/PoweredByContext'
import {getUrl} from '../utilities/url'
import QuoteQueryJets from '../components/QuoteQueryJets'

const Blog = () => {

    const perPage = 12
    const [articlesList, setArticlesList] = useState([])
    const [loadingMore, setLoadingMore] = useState(false)
    const [page, setPage] = useState(0)
    const [firstArticle, setFirstArticle] = useState(0)
    const {returnData: articles} = useGet(`/api/content/blog?page=${page}&pageSize=${perPage}`)

    const poweredByCtx = useContext(poweredByContext)

    useEffect(() => {
        document.documentElement.style.scrollSnapType = 'none'
    }, [])

    useEffect(() => {
        if (articles) {
            if (articles.results[0]) {
                setFirstArticle(articles.results[0].id)
                setArticlesList(articlesList.concat(articles.results))
            }
        }
    }, [articles])

    useEffect(() => {
        setLoadingMore(false)
    }, [articlesList])

    const loadMore = () => {
        setLoadingMore(true)
        setPage(page + 1)
    }

    return (
        <>
            <SEO
                title={articles.metaTitle}
                description={articles.metaDescription}
                canonicalUrl={getUrl(poweredByCtx, window.location.pathname)}
            />
                    
            {articles?.stickyQuoteBool && (
                <div className='wrap'>
                    <QuoteQueryJets onPage />
                </div>
            )}

            <div className='blog'>
                <div className='wrap'>
                    <header className='blog__header'>
                        <h1>SHY Aviation Blog</h1>
                    </header>

                    {articles
                        ?
                        <>
                            <div className='blog__list'>
                                {articlesList.map((article, i) => {
                                    return <BlogArticlePreview data={article} key={article.id} scrollToArticle={article.id === firstArticle && page !== 0} />
                                })}
                            </div>
                            <div className='blog__footer'>
                                {loadingMore
                                    ?
                                    <Loading />
                                    :
                                    page < articles.totalPages - 1
                                    ?
                                    <Button onClick={loadMore} classNames={['button--full', 'button--tertiary']} animate={true}>Load more</Button>
                                    :
                                    null
                                }
                            </div>
                        </>
                        :
                        <Loading />
                    }
                </div>
            </div>

            <NewsletterForm />
        </>
    )
}

export default Blog