import {useContext, useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Parallax} from 'react-scroll-parallax'
import useGet from '../hooks/useGet'
import Loading from '../components/Loading'
import SEO from '../components/SEO'
import BlockAircraft from '../components/BlockAircraft'
import BlockArticles from '../components/BlockArticles'
import BlockFAQs from '../components/BlockFAQs'
import BlockHero from '../components/BlockHero'
import BlockText from '../components/BlockText'
import BlockTextAndImage from '../components/BlockTextAndImage'
import Modal from '../components/Modal'
import ServiceRequest from '../components/ServiceRequest'
import QuoteQueryJets from '../components/QuoteQueryJets'
import getClassName from '../utilities/getClassName'
import {getUrl} from '../utilities/url'
import PoweredByContext from '../store/PoweredByContext'

const LandingPage = ({ showModalOnLoad = false }) => {
    const pageSlug = useParams().page
    const {error, returnData: page} = useGet(`/api/landingpages/v2?path=/${pageSlug}`)
    const [showModal, setShowModal] = useState(showModalOnLoad)
    const navigate = useNavigate()

    const poweredByCtx = useContext(PoweredByContext)

    useEffect(() => {
        setShowModal(showModalOnLoad)
    }, [showModalOnLoad])

    const usePrevious = (showModal) => {
        const prev = useRef()

        useEffect(() => {
            prev.current = showModal
        }, [showModal])

        return prev.current
    }

    const prevShowModal = usePrevious(showModal)

    useEffect(() => {
        if (showModal === false && prevShowModal === true) {
            let pathParts = window.location.pathname.split('/')

            const destination = pathParts.filter((part, i) => {
                return part !== 'request' && i !== pathParts.length - 1
            }).join('/')

            navigate(destination)
        }
    }, [showModal])


    useEffect(() => {
        if (error) {
            navigate('/')
        }
    }, [error])

    const getHeroService = () => {
        let service = null

        page?.contentBlocks?.forEach((block) => {
            if (block.blockName === 'hero') {
                service = block.props.service
            }
        })
        
        return service
    }

    const getBlockComponent = (name) => {
        if (name === 'aircraftCarousel') {
            return BlockAircraft
        } else if (name === 'relatedArticles') {
            return BlockArticles
        } else if (name === 'faq') {
            return BlockFAQs
        } else if (name === 'hero') {
            return BlockHero
        } else if (name === 'textWithSection') {
            return BlockText
        } else if (name === 'textWithCta') {
            return BlockTextAndImage
        } else if (name === 'testimonial') {
            return BlockTextAndImage
        }
    }

    const getTestimonials = (block) => {
        let testimonials = []

        block.props.contentBlocks.forEach((item) => {
            testimonials.push({
                text: item.props.text,
                author: item.props.author
            })
        })

        return testimonials
    }

    const getFAQs = (block) => {
        let faqs = []

        if (block.props.contentBlocks) {
            block.props.contentBlocks.forEach((item) => {
                faqs.push({
                    question: item.props.faq,
                    answer: item.props.answerFAQ
                })
            })
        }

        return faqs
    }

    const getBlockProps = (block) => {
        let props = {}
        if (block.blockName === 'aircraftCarousel') {
            return Object.assign(props, {
                title: block.props.title,
                subtitle: block.props.subtitle,
                items: block.props.contentBlocks,
                ctaUrl: block.props.links[0] ? block.props.links[0].url : undefined,
                ctaText: block.props.links[0] ? block.props.links[0].name : undefined,
                mainHeading: block.props.mainHeading,
                subHeading: block.props.subHeading
            })
        } else if (block.blockName === 'faq') {
            return Object.assign(props, {
                title: block.props.title,
                subtitle: block.props.subtitle,
                text: block.props.text,
                showMoreText: block.props.showMoreText,
                items: getFAQs(block),
                ctaUrl: block.props.links[0] ? block.props.links[0].url : undefined,
                ctaText: block.props.links[0] ? block.props.links[0].name : undefined,
                mainHeading: block.props.mainHeading,
                subHeading: block.props.subHeading
            })
        } else if (block.blockName === 'textWithSection') {
            return Object.assign(props, {
                textBlocks: block.props.contentBlocks,
                ctaUrl: block.props.links[0] ? block.props.links[0].url : undefined,
                ctaText: block.props.links[0] ? block.props.links[0].name : undefined
            })
        } else if (block.blockName === 'textWithCta') {
            const defaultProps = Object.assign(props, block.props)

            return Object.assign(defaultProps, {
                ctaUrl: block.props.links[0] ? block.props.links[0].url : undefined,
                ctaText: block.props.links[0] ? block.props.links[0].name : undefined
            })
        } else if (block.blockName === 'testimonial') {
            const defaultProps = Object.assign(props, block.props)

            return Object.assign(defaultProps, {
                testimonials: getTestimonials(block)
            })
        } else {
            return Object.assign(props, block.props)
        }
    }

    const [animate, setAnimate] = useState([])

    // Animate In Out with parallax
    const animateInOutHandler = (progress, percent, animRef) => {
        if (progress > percent && !animate[animRef]) {
            const updateAnim = {...animate, [animRef]: true}
            setAnimate(updateAnim)
        }

        if (progress === 0 && animate[animRef]) {
            const updateAnim = {...animate, [animRef]: false}
            setAnimate(updateAnim)
        }
    }

    const canShowModal = () => {
        return getHeroService() === 'Helicopters' || getHeroService() === 'Group Charter'
    }

    return (
        <>
            {page &&
                <SEO
                    title={page.metaTitle}
                    description={page.metaDescription}
                    canonicalUrl={getUrl(poweredByCtx, window.location.pathname)}
                />
            }
            <div className='landing-page'>
                {(page && poweredByCtx.siteReady) ?
                    <div className='landing-page__content'>
                        {page.stickyQuoteBool && (
                            <div className='wrap'>
                                <QuoteQueryJets onPage />
                            </div>
                        )}
                        {page.contentBlocks.map((block, i) => {
                            const Component = getBlockComponent(block.blockName)

                            if (Component && !(poweredByCtx.isPoweredBy && (block.blockName === 'testimonial' || block.blockName === 'relatedArticles'))) {
                                return (
                                    <div key={i}>
                                        <Parallax
                                            onProgressChange={(progress) => {
                                                animateInOutHandler(progress, 0.15, `block_${i}`)
                                            }}
                                            className={getClassName(['fade-and-travel', `parallax-block-${block.blockName}`, animate[`block_${i}`] && 'fade-and-travel--visible'])}
                                        >
                                            <Component {...getBlockProps(block)} />
                                        </Parallax>
                                    </div>
                                )
                            }
                        })}
                    </div>
                :
                    <Loading/>
                }
            </div>
            {canShowModal() &&
                <Modal
                    modalVisible={showModal}
                    closeModal={() => {
                        setShowModal(false)
                    }}
                >
                    <ServiceRequest service={getHeroService()} />
                </Modal>
            }
        </>
    )
}

export default LandingPage