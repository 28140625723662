import React, {forwardRef, useContext, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router'
import Button from './Button'
import ThreeDots from './ThreeDots'
import MoreLessButton from './MoreLessButton'
import getClassName from '../utilities/getClassName'
import GetClassName from '../utilities/getClassName'
import PhotoCarousel from './PhotoCarousel'
import OverlayFade from './OverlayFade'
import {NumericFormat, useNumericFormat} from 'react-number-format'
import ScaleText from "react-scale-text";
import PoweredByContext from '../store/PoweredByContext'

// Import icons
import departureTimeIcon from '../assets/icons/departure-time.svg'
import arrivalTimeIcon from '../assets/icons/arrival-time.svg'
import seatsIcon from '../assets/icons/seats.svg'
import headRoomIcon from '../assets/icons/head-room.svg'
import co2Icon from '../assets/icons/co2.svg'
import infoIcon from '../assets/icons/info-blue.svg'

import Share from './Share'
import Modal from './Modal'
import Thumb from './Thumb'
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import CloseCross from './CloseCross'
import Checkbox from './Checkbox'

const AircraftCard = forwardRef(({
                                     id,
                                     legId,
                                     recommended,
                                     category,
                                     aircraftName,
                                     photoData,
                                     seats,
                                     departureTime,
                                     arrivalTime,
                                     headRoom,
                                     co2,
                                     yom,
                                     price,
                                     currency,
                                     operatorCurrency,
                                     dimAircraft,
                                     expandedId,
                                     expandedHandler,
                                     exitAircraft,
                                     enterAircraft,
                                     sharedPage,
                                     sharerName,
                                     approvalRequested,
                                     thumbsStatus,
                                     updateThumbsStatus,
                                     tailNumber,
                                     showCheckbox,
                                     compareSelectionHandler,
                                     compareSelected
                                 }, ref) => {

    // State
    const [showSharePanel, setShowSharePanel] = useState(false)
    const [messages, setMessages] = useState([])

    // Hooks
    const navigate = useNavigate()
    const thumbMessage1 = useRef()
    const thumbMessage2 = useRef()
    const poweredByCtx = useContext(PoweredByContext)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    // Show price info message
    const showPriceInfoHandler = () => {
        setMessages((oldState) => {
                return [{
                    id: (oldState.length === 0) ? 0 : oldState[0]['id'] + 1,
                    text: `Price shown in ${operatorCurrency} is the original price provided by the Operator`
                }]
            }
        )
    }

    const updateExpandedStatus = () => {
        expandedHandler(id)
    }

    // Format time
    const checkDepartureTime = departureTime.split(' ')
    if (typeof checkDepartureTime === 'object' && checkDepartureTime.length === 2) {
        departureTime = checkDepartureTime[1]
    }

    const checkArrivalTime = arrivalTime.split(' ')
    if (typeof checkArrivalTime === 'object' && checkArrivalTime.length === 2) {
        arrivalTime = checkArrivalTime[1]
    }

    // Go to booking page
    const goToBookingPage = () => {
        navigate(`/book?lo=${legId}&name=${queryParams.get('name')}`, {
            state: {
                legid: legId,
                arfq: id,
                tailNum: tailNumber
            }
        })
    }

    // Parse carbon calculation
    const carbonCalc = useNumericFormat({
        displayType: 'text',
        value: parseFloat(co2),
        decimalScale: 2
    })

    return (
        <>
            <div className='aircraft-card' ref={ref}>

                <div className={GetClassName(
                    [
                        'aircraft-card__content',
                        enterAircraft && 'aircraft-card__content--enter',
                        exitAircraft && 'aircraft-card__content--exit'
                    ])}>

                    <div className='aircraft-card__photos-browser'>
                        <PhotoCarousel
                            photoData={photoData}
                            bannerTitle={recommended ? poweredByCtx.isPoweredBy ? 'Recommended' : 'Shy Recommends' : null}
                        />

                        <div className='aircraft-card__gradient'>
                            <div className='aircraft-card__category'>{category} - {yom}</div>
                            <div className='aircraft-card__aircraft-name'>{aircraftName}</div>
                        </div>

                        <TransitionGroup>
                            {
                                messages.map(message =>
                                    <CSSTransition
                                        nodeRef={(message.id % 2 === 0) ? thumbMessage1 : thumbMessage2}
                                        timeout={450}
                                        key={message.id}
                                        classNames={{
                                            enterActive: 'aircraft-card__notification--entering',
                                            enterDone: 'aircraft-card__notification--enter-done',
                                            exitActive: 'aircraft-card__notification--exiting'
                                        }}
                                    >
                                        <div
                                            ref={(message.id % 2 === 0) ? thumbMessage1 : thumbMessage2} className='aircraft-card__notification'>
                                            {message.text}
                                            <div className='aircraft-card__notification__close-wrapper'>
                                                <CloseCross onClick={() => setMessages([])} />
                                            </div>
                                        </div>
                                    </CSSTransition>
                                )
                            }
                        </TransitionGroup>
                    </div>

                    <div className={getClassName(['aircraft-card__price-row', recommended && 'aircraft-card__price-row--shy-recommends'])}>

                        <div  className='aircraft-card__prices'>
                            <div className='aircraft-card__price aircraft-card__price--operator' >
                                <ScaleText widthOnly maxFontSize={22} minFontSize={0}>
                                    <>
                                        {operatorCurrency === "GBP" && <>GBP</>}
                                        {operatorCurrency === "USD" && <>USD</>}
                                        {operatorCurrency === "EUR" && <>EUR</>}
                                        &nbsp;
                                        <NumericFormat
                                            displayType='text'
                                            value={operatorCurrency ? price[operatorCurrency.toLowerCase()] : 0}
                                            thousandSeparator
                                            decimalScale={0}
                                        />
                                    </>
                                </ScaleText>
                            </div>

                            <div className='aircraft-card__price aircraft-card__price--customer'>
                                <ScaleText widthOnly maxFontSize={16} minFontSize={0}>
                                    <>
                                        {currency === "GBP" && <>GBP</>}
                                        {currency === "USD" && <>USD</>}
                                        {currency === "EUR" && <>EUR</>}
                                        &nbsp;
                                        <NumericFormat
                                            displayType='text'
                                            value={price[currency.toLowerCase()]}
                                            thousandSeparator
                                            decimalScale={0}
                                        />
                                    </>
                                </ScaleText>
                                <button onClick={showPriceInfoHandler} className='aircraft-card__info'>
                                    Click for price info
                                    <img src={infoIcon} alt='Click for price info' />
                                </button>
                            </div>

                        </div>

                        {!sharedPage ?
                            <>
                                <div className='aircraft-card__buttons-wrapper aircraft-card__buttons-wrapper--align-button-right'>
                                    <Button onClick={goToBookingPage}>Reserve Now</Button>
                                </div>

                                <ThreeDots
                                    buttonText='Quick Links'
                                >
                                    <Button onClick={() => setShowSharePanel(true)} classNames={['button--no-border']} icon='share'>Share</Button>
                                </ThreeDots>
                            </>
                            :
                            <>
                                {(approvalRequested === 'true') &&
                                    <Thumb
                                        status={thumbsStatus}
                                        updateStatus={(status) => updateThumbsStatus(status)}
                                    />
                                }
                            </>
                        }
                    </div>

                    <div className='aircraft-card__icons-row'>
                        <div className='aircraft-card__icon-wrapper'>
                            <img src={departureTimeIcon} alt='' />
                            {departureTime}
                        </div>

                        <div className='aircraft-card__icon-wrapper'>
                            <img src={arrivalTimeIcon} alt='' />
                            {arrivalTime}
                        </div>

                        <div className='aircraft-card__icon-wrapper'>
                            <img src={seatsIcon} alt='' />
                            {seats}
                        </div>

                        <div className='aircraft-card__icon-wrapper'>
                            <img src={headRoomIcon} alt='' />
                            {headRoom}
                        </div>

                        <div className='aircraft-card__icon-wrapper'>
                            <img src={co2Icon} alt='' />
                            {carbonCalc.value} T
                        </div>

                    </div>

                    <MoreLessButton onClick={updateExpandedStatus} selected={expandedId === tailNumber} />

                </div>

                <OverlayFade spinner={exitAircraft || enterAircraft} show={dimAircraft || exitAircraft} />
                <OverlayFade show={showCheckbox} opacityPercentage={0.1} />

                {showCheckbox &&
                    <div className='aircraft-card__checkbox-wrapper' >
                        <Checkbox defaultValue={compareSelected} classNames={['checkbox--aircraft-compare']} tickDark value={(value) => compareSelectionHandler(value)} />
                    </div>
                }

                {/* Big overlay button so another aircraft can be selected when tray is visible */}
                {(expandedId && (expandedId !== id) ) && <button onClick={updateExpandedStatus} className='aircraft-card__big-expand-button' />}

            </div>

            <Modal
                classNames={['modal__content--thin']}
                modalVisible={showSharePanel}
                closeModal={() => {setShowSharePanel(false)}}
            >
                <Share
                    type='flight'
                    endpoint={'/api/frontend/share-quote'}
                    shareRef={[id]}
                    close={() => {setShowSharePanel(false)}}
                    tailNumbers={[tailNumber]}
                />
            </Modal>
        </>
    )
})

export default AircraftCard