import {useCallback, useEffect, useRef, useState} from 'react'
import InputError from './InputError'
import getClassName from '../utilities/getClassName'
import Label from './Label'
import errorCross from '../assets/icons/error-cross.svg'
import tickGreen from '../assets/icons/tick-green.svg'
import Fieldset from './Fieldset'
import 'react-phone-number-input/style.css'
import PhoneInput, {formatPhoneNumber, formatPhoneNumberIntl} from 'react-phone-number-input'
import useGeoLocation from 'react-ipgeolocation'

const TextInput = ({value, label, id, type, required, validation, defaultValue, maxLength, disabled, validateForm, matchPassword}) => {

    // State
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const [focussed, setFocussed] = useState(id === 'phone')
    const [interacted, setInteracted] = useState(false)
    const [skipAnimation, setSkipAnimation] = useState(false)

    // Hooks
    const input = useRef()
    const location = useGeoLocation()

    const getCountryCode = () => {
        if (id === 'phone') {
            const select = input.current.parentNode.querySelector('.PhoneInputCountrySelect')

            return select?.value
        }
    }

    // Validate input
    const validationHandler = useCallback((blurOccurred) => {

        if (blurOccurred === true) {
            setShowError(true)
        }

        if (value) {
            value(null)

            const currentValue = input.current.value.replace(/\s/g, '')

            switch (true) {
                case (required && currentValue.length === 0):
                    setError(`${label.replace('*', '')} is required`)
                    return
                case (matchPassword && (showError || (blurOccurred === true)) && (matchPassword !== currentValue)):
                    setError(`Passwords do no match`)
                    return
                case (required && (showError || (blurOccurred === true)) && (validation === 'email') && !/\S+@\S+\.\S+/.test(currentValue)):
                    setError(`Invalid email`)
                    return
                case (id === 'phone' && getCountryCode() === 'ZZ'):
                    setError(`Please choose a country`)
                    return
                default:
                    setInteracted(true)
                    setError('')
                    value(input.current.value)
                    return
            }
        }
    }, [label, matchPassword, required, showError, validation, value])

    // On blur handler
    const onBlurHandler = () => {
        validationHandler(true)
        setSkipAnimation(false)
        const currentValue = input.current.value.replace(/\s/g, '')
        if (currentValue.length === 0) {
            setFocussed(false)
        }
    }

    // Force validation / Check for autofill / Default updated
    useEffect(() => {
        if (validateForm) {
            validationHandler()
        }
        if (input.current.value) {
            setFocussed(true)
            setSkipAnimation(true)
        }
        if (defaultValue) {
            input.current.value = defaultValue
        }
    }, [defaultValue, validateForm, validationHandler, value])

    useEffect(() => {
        if (id === 'phone') {
            const select = input.current.parentNode.querySelector('select')
            if (select) {
                const internationalOption = select.querySelector('option[value="ZZ"]')
                let separator = select.querySelector('option[disabled]')
                if (!separator) {
                    separator = document.createElement('option')
                    separator.innerHTML = '---------------'
                    separator.disabled = true
                }
                const priority = [
                    select.querySelector('option[value="GB"]'),
                    select.querySelector('option[value="US"]'),
                    select.querySelector('option[value="FR"]'),
                    select.querySelector('option[value="DE"]'),
                    select.querySelector('option[value="IT"]'),
                    select.querySelector('option[value="CH"]'),
                    separator
                ]

                priority.forEach((option) => {
                    if (option) {
                        select.insertBefore(option, internationalOption)
                    }
                })
            }
        }
    }, [id, input.current])

    return (
        <Fieldset marginBottom>
            <div className={getClassName(['text-input text-input--margin-bottom', disabled && 'text-input--disabled'])}>
                {error && <InputError>{error}</InputError>}
                {id === 'phone'
                    ?
                    <PhoneInput
                        ref={input}
                        international
                        value={defaultValue}
                        defaultCountry={location?.country}
                        onFocus={() => setFocussed(true)}
                        onBlur={onBlurHandler}
                        onChange={validationHandler}/>
                    :
                    <input
                        defaultValue={defaultValue}
                        ref={input}
                        type={(type === 'password' ? 'password' : 'text')}
                        id={id}
                        name={id}
                        autoComplete='on'
                        onFocus={() => setFocussed(true)}
                        maxLength={maxLength}
                        onBlur={onBlurHandler}
                        onChange={validationHandler}
                        className={getClassName(['text-input__input', error && 'text-input__input--error', (interacted && !error) && 'text-input__input--success'])}
                        disabled={disabled}
                    />
                }

                {error && <img className='text-input__error-cross' src={errorCross} alt='' />}
                {(interacted && !error) && <img className='text-input__tick' src={tickGreen} alt='' />}
                {label && <Label minimised={focussed} skipAnimation={skipAnimation} htmlFor={id}>{label}</Label>}
            </div>
        </Fieldset>
    )
}

export default TextInput