import React, {useEffect, useRef, useState} from 'react'
import AircraftBrowserFilters from './AircraftBrowserFilters'
import AircraftBrowserResults from './AircraftBrowserResults'
import useGet from '../hooks/useGet'
import filters from '../data/aircraftBrowserFilters'

const AircraftBrowser = () => {
    
    // State
    const [category, setCategory] = useState(filters[0].slug)
    const [description, setDescription] = useState(null)
    const [changeCategoryTo, setChangeCategoryTo] = useState(null)
    const [initialSlide, setInitialSlide] = useState(0)

    // Hooks
    const numberToReturn = 3
    const resultsUrl = `/api/content/gethpaircraft?category=${category}`
  
    const {loading: resultsLoading, error: resultsError, returnData: results} = useGet(resultsUrl)

    useEffect(() => {
        // Assign a reference to each filter
        filters.forEach(filter => {
            filter.ref = React.createRef()
        })
    }, [])

    useEffect(() => {
        if (results) {
            if (results[0]) {
                setDescription(results[0].categoryDescription)
            }
        }
    }, [results])

    const filtersUpdated = (active, resetInitialSlide) => {
        if (active) {
            setCategory(active.slug)
            if (resetInitialSlide) {
                setInitialSlide(0)
            }
        }
    }

    const changeCategory = (direction) => {
        setChangeCategoryTo(direction)
        if (direction) {
            if (direction === 'previous') {
                setInitialSlide(numberToReturn)
            }
            if (direction === 'next') {
                setInitialSlide(0)
            }
        }
    }

    return (
        <>
            <AircraftBrowserFilters filters={filters} onUpdate={filtersUpdated.bind(this)} changeCategoryTo={changeCategoryTo} />
            <AircraftBrowserResults description={description} results={results ? results[0]?.aircraftCategoryList : []} changeCategory={changeCategory} initialSlide={initialSlide} />
        </>
    )
}

export default AircraftBrowser