export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const stringsMatch = (a, b) => {
    a = cleanString(a)
    b = cleanString(b)

    return a === b
}

export const cleanString = (string) => {
    return deconstructString(string).join(' ')
}

export const deconstructString = (string) => {
    return string.toLowerCase().split(' ').filter(part => {
        return part !== ''
    })
}

export const replaceShyName = (str, isPoweredBy, tenantName) => {
    if (isPoweredBy && tenantName) {
        return str.replace(/shy aviation|shy/gi, tenantName)
    }
    return str
}