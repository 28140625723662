import {useContext, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import parse from 'html-react-parser'
import Button from './Button'
import Testimonials from './Testimonials'
import poweredByContext from '../store/PoweredByContext'
import getClassName from '../utilities/getClassName'
import {isInternalUrl} from '../utilities/url'
import {replaceShyName} from '../utilities/string'
import createHeading from '../utilities/createHeading'

const BlockTextAndImage = ({ service, image, title, subtitle, text, testimonials, showMoreText, ctaUrl, ctaText, imageAllignment, mainHeading, subHeading }) => {

    // State
    const [showMore, setShowMore] = useState(false)

    // Context
    const poweredByCtx = useContext(poweredByContext)

    // Ref
    const containerRef = useRef()
    const contentRef = useRef()

    /*
    * Replace Shy Aviation with the tenant name, if necessary
    */
    const replaceName = (str) => {
        if (str) {
            return replaceShyName(str, poweredByCtx.isPoweredBy, poweredByCtx?.tenant?.tenantName)
        }
    }

    /*
    * Show the full text
    */
    const showText = () => {
        setShowMore(true)
    }

    /*
    * Check if the read more is needed on page load
    */
    useEffect(() => {
        if (showMoreText) {
            if (containerRef.current.clientHeight >= contentRef.current.clientHeight) {
                if (!window.matchMedia('(min-width: 765px)').matches) {
                    setShowMore(true)
                }
            }
        }
    }, [showMoreText])

    return (
        <section className={getClassName(['block-text-and-image', service === 'Private Jets' ? 'block-text-and-image--private-jets' : undefined, (service === 'Helicopters' || service === 'Group Charter') ? 'block-text-and-image--service' : undefined, imageAllignment ? 'block-text-and-image--alt' : undefined])}>
            <div className='block-text-and-image__grid'>
                <figure className='block-text-and-image__image'>
                    <img src={image.url} alt={image.alt} />
                </figure>
                <div className='block-text-and-image__content'>
                    <header className='block-header'>
                        {createHeading(subHeading, replaceName(subtitle), true)}
                        {createHeading(mainHeading, replaceName(title), false)}
                    </header>
                    {text &&
                        <>
                            {showMoreText
                                ?
                                    <div className={getClassName(['block-text-and-image__text', 'block-read-more', 'block-read-more--3', showMore ? 'block-read-more--active' : undefined])}>
                                        <div className='block-read-more__container' ref={containerRef}>
                                            <div className='block-read-more__content' ref={contentRef}>
                                                {parse(replaceName(text))}
                                            </div>
                                        </div>
                                        <button className='block-read-more__button' onClick={showText}>{showMoreText}</button>
                                    </div>
                                :
                                    <div className={getClassName(['block-text-and-image__text'])}>
                                        {parse(replaceName(text))}
                                    </div>
                            }
                        </>
                    }
                    {testimonials
                        ? 
                            <Testimonials testimonials={testimonials} />
                        :
                            <>
                                {ctaUrl && ctaText &&
                                    <div className='block-text-and-image__footer'>
                                        <>
                                            {isInternalUrl(ctaUrl)
                                                ?
                                                    <Link to={ctaUrl} className='button button--full'>
                                                        <span>{replaceName(ctaText)}</span>
                                                    </Link>
                                                :
                                                    <a href={ctaUrl} className='button button--full'>
                                                        <span>{replaceName(ctaText)}</span>
                                                    </a>
                                            }
                                        </>
                                    </div>
                                }
                            </>
                    }
                </div>
            </div>
        </section>
    )
}

export default BlockTextAndImage