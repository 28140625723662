import {useContext, useEffect, useState} from 'react'
import parse from 'html-react-parser'
import TitleBar from '../components/TitleBar'
import TabBar from '../components/TabBar'
import Tab from '../components/Tab'
import LoadingOverlay from '../components/LoadingOverlay'
import {Link} from 'react-router-dom'
import Collapsible from 'react-collapsible';
import chevronDownIcon from '../assets/icons/chevron-down.svg'
import chevronUpIcon from '../assets/icons/chevron-up-double.svg'
import SEO from '../components/SEO'
import {useGetCacheContent} from '../hooks/useGetCacheContent'
import {scrollToTop} from '../../src/utilities/scrolltoTop' 
import poweredByContext from '../store/PoweredByContext'
import {getUrl} from '../utilities/url'

const PrivacyPolicy = () => {

    // State
    const [expandable, setExpandable] = useState(true)
    const [allCollapsiblesOpen, setAllCollapsiblesOpen] = useState([])

    // Get and cache content
    const {content: privacyPolicyContent} = useGetCacheContent('privacyPolicyContent', '/api/frontend/get-privacy-policy', 500)
    
    const poweredByCtx = useContext(poweredByContext)

    useEffect(() => {
        let items = []
        
        if (privacyPolicyContent) {
            privacyPolicyContent.forEach((item, i) => {
                items.push(false)
            })
        }
        
        setAllCollapsiblesOpen(items) 
    }, [privacyPolicyContent])

    useEffect(() => {
        if (allCollapsiblesOpen.length > 0) {
            let valid = true;

            if (expandable) {
                allCollapsiblesOpen.forEach((item) => {
                    if (item === false) {
                        valid = false
                    }
                })
                if (valid) {
                    setExpandable(false)
                }
            } else {
                allCollapsiblesOpen.forEach((item) => {
                    if (item === true) {
                        valid = false
                    }
                })
                if (valid) {
                    setExpandable(true)
                }
            }
        }
    }, [allCollapsiblesOpen, expandable])

    const changeAll = () => {
        if (expandable) {
            openAll()
        } else {
            closeAll()
        }
    }

    const openAll = () => {
        let items = []

        JSON.parse(JSON.stringify(allCollapsiblesOpen)).forEach((item, i) => {
            if (!item) {
                item = true
            }
            items.push(true)
        })

        setAllCollapsiblesOpen(items)
        setExpandable(false)
    }

    const closeAll = () => {
        let items = []

        JSON.parse(JSON.stringify(allCollapsiblesOpen)).forEach((item, i) => {
            if (!item) {
                item = false
            }
            items.push(false)
        })

        setAllCollapsiblesOpen(items)
        setExpandable(true)
    }

    const updateCollapsible = (i, open) => {
        let items = JSON.parse(JSON.stringify(allCollapsiblesOpen))
        items[i] = open

        setAllCollapsiblesOpen(items)
    }

    return (
        <>
            {privacyPolicyContent &&
                <SEO
                    title={privacyPolicyContent.metaTitle}
                    description={privacyPolicyContent.metaDescription}
                    canonicalUrl={getUrl(poweredByCtx, window.location.pathname)}
                />
            }

            <TitleBar title='Terms & Privacy Policy' cta='Home' url='/'/>

            {(!privacyPolicyContent || !poweredByCtx.siteReady) ?
                <div className='page'>
                    <LoadingOverlay/>
                </div>
                :
                <>
                    <TabBar>
                        <Link to={`/privacy-policy`}><Tab selected={true}>Privacy Policy</Tab></Link>
                        <Link to={`/terms-conditions`}><Tab>Terms & Conditions</Tab></Link>
                    </TabBar>
                    <div className='page'>
                        <div className='wrap wrap--1040'>
                            <div className='collapsible-content'>
                                <div className='collapsible-content__header'>
                                    <div className='collapsible-content__intro'>
                                        <p><b>Our privacy policy is outlined below, please read through it and contact us with any questions.</b></p>
                                    </div>
                                    <button className='collapsible-content__toggle' onClick={changeAll}>{expandable ? 'Expand all' : 'Collapse all'} <img src={chevronDownIcon} alt='' /></button>
                                </div>
                                <div className='collapsible-content__items'>
                                    {privacyPolicyContent.map((item, i) => {
                                        return <Collapsible
                                            open={allCollapsiblesOpen[i]}
                                            onTriggerOpening={updateCollapsible.bind(this, i, true)}
                                            onTriggerClosing={updateCollapsible.bind(this, i, false)}
                                            trigger={item.sectionTitle}
                                            key={i}
                                        >
                                            {parse(item.terms)}
                                        </Collapsible>
                                    })}
                                </div>
                                <div className='collapsible-content__footer'>
                                    <button className='collapsible-content__to-top' onClick={scrollToTop}><img src={chevronUpIcon} alt='' /> Back to top</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default PrivacyPolicy