import {useLocation} from 'react-router'
import {useContext, useState} from 'react'
import Image from '../components/Image'
import {Parallax} from 'react-scroll-parallax'
import getClassName from '../utilities/getClassName'
import Button from '../components/Button'
import {Pagination} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'
import AmbassadorForm from '../components/AmbassadorForm'
import { useRef } from 'react'
import SEO from '../components/SEO'
import {useScrollToHash} from '../hooks/useScrollToHash'
import {Link} from 'react-router-dom'
import {useGetCacheContent} from '../hooks/useGetCacheContent'
import parse from 'html-react-parser'
import LoadingOverlay from '../components/LoadingOverlay'
import {getUrl} from '../utilities/url'
import poweredByContext from '../store/PoweredByContext'
import QuoteQueryJets from '../components/QuoteQueryJets'

const Ambassadors = () => {

    // State
    const [animate, setAnimate] = useState([])

    const poweredByCtx = useContext(poweredByContext)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const submitParam = queryParams.get('tm');
    const didSubmit = !!(submitParam && submitParam === '1')
    const doScroll = useScrollToHash()
    const tref = useRef('amb-thanks')

    // Get and cache content
    const {content: ambassadorsContent} = useGetCacheContent('ambassadorsContent', '/api/content/ambassador', 500)

    // Animate In Out with parallax
    const animateInOutHandler = (progress, percent, animRef) => {
        if (progress > percent && !animate[animRef]) {
            const updateAnim = {...animate, [animRef]: true}
            setAnimate(updateAnim)
        }

        if (progress === 0 && animate[animRef]) {
            const updateAnim = {...animate, [animRef]: false}
            setAnimate(updateAnim)
        }
    }

    return (
        <>
            {!ambassadorsContent ?
                <LoadingOverlay clear />
                :
                <>
                    {ambassadorsContent &&
                        <SEO
                            title={ambassadorsContent.topSection.metaTitle}
                            description={ambassadorsContent.topSection.metaDescription}
                            canonicalUrl={getUrl(poweredByCtx, window.location.pathname)}
                        />
                    }
                            
                    {ambassadorsContent?.stickyQuoteBool && (
                        <div className='wrap'>
                            <QuoteQueryJets onPage />
                        </div>
                    )}
                    
                    <div className='brochure'>

                        <section className={ambassadorsContent?.stickyQuoteBool ? '' : 'brochure__first-section2'}>

                            <div className='brochure__title-band'>
                                <h1>{ambassadorsContent.topSection.title}</h1>
                            </div>

                            <div className='brochure__banner'>
                                <Image src={ambassadorsContent.topSection.imageHolder.url} alt={ambassadorsContent.topSection.imageHolder.alt} fillParent showLoading />
                            </div>
                        </section>

                        <section>
                            <div className='brochure__title-band brochure__title-band--grey'>
                                <h2>{ambassadorsContent.titleStrip1.subTitle}</h2>
                                <h1>{ambassadorsContent.titleStrip1.title}</h1>
                            </div>
                            <div className='wrap brochure__inner'>

                                {/* Title 1 */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'title1')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.title1 && 'fade-and-travel--visible'])}
                                >
                                    <h2>{ambassadorsContent.commission.subTitle}</h2>
                                    <h1>{ambassadorsContent.commission.title}</h1>
                                </Parallax>

                                {/* Initiative */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'initiative')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.initiative && 'fade-and-travel--visible'])}
                                >
                                    <div className='brochure__centered-text'>
                                        {parse(ambassadorsContent.commission.body)}

                                        {/* Button 1 */}
                                        <Parallax
                                            opacity={[0, 4]}
                                            className='brochure__button-wrapper'
                                        >
                                            <Link to='/blog/here-is-why-our-ambassador-programme-is-right-for-you'><Button classNames={['button--full', 'button--tertiary']} animate={true}>Discover more</Button></Link>
                                        </Parallax>
                                    </div>
                                </Parallax>
                            </div>
                        </section>

                        <section className='brochure__section--white'>
                            <div className='wrap brochure__inner'>

                                {/* Title 2 */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'title2')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.title2 && 'fade-and-travel--visible'])}
                                >
                                    <h2>{ambassadorsContent.shyAmbassadors.subTitle}</h2>
                                    <h1>{ambassadorsContent.shyAmbassadors.title}</h1>
                                </Parallax>


                                {/* Work anywhere */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'workAnywhere')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.workAnywhere && 'fade-and-travel--visible'])}
                                >
                                    <div className='brochure__centered-text'>
                                        {parse(ambassadorsContent.shyAmbassadors.body)}
                                    </div>
                                </Parallax>


                            </div>
                        </section>

                        <section>
                            <div className='wrap brochure__inner'>

                                {/* Title 3 */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'title3')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.title3 && 'fade-and-travel--visible'])}
                                >
                                    <h2>{ambassadorsContent.whyWorkWithUs.subTitle}</h2>
                                    <h1>{ambassadorsContent.whyWorkWithUs.title}</h1>
                                </Parallax>

                                {/* Foundation */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'foundation')
                                    }}
                                    className={getClassName(['brochure__2-column-layout', 'fade-and-travel', animate.foundation && 'fade-and-travel--visible'])}
                                >
                                    <div className='brochure__2-column-layout__image'>
                                        <Image fillParent showLoading src={ambassadorsContent.whyWorkWithUs.imageHolder.url} alt={ambassadorsContent.whyWorkWithUs.imageHolder.alt}/>
                                    </div>

                                    <div className='brochure__2-column-layout__text'>
                                        {parse(ambassadorsContent.whyWorkWithUs.body)}
                                    </div>
                                </Parallax>
                            </div>
                        </section>

                        <section>
                            <div className='brochure__title-band brochure__title-band--grey'>
                                <h1>{ambassadorsContent.titleStrip2.title}</h1>
                            </div>
                        </section>

                        <section>
                            <div className='brochure__inner'>

                                {/* Title 4 */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'title4')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.title4 && 'fade-and-travel--visible'])}
                                >
                                    <h2>Services we offer</h2>
                                    <h1>Sales Offering</h1>
                                </Parallax>

                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'swiper')
                                    }}
                                    className={getClassName(['brochure__swiper-wrapper', 'fade-and-travel', animate.swiper && 'fade-and-travel--visible'])}
                                >
                                    <Swiper
                                        grabCursor={true}
                                        speed={400}
                                        loop={true}
                                        modules={[Pagination]}
                                        touchStartPreventDefault={false}
                                        className='swiper'
                                        slidesPerView='1.1'
                                        pagination={true}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1.1
                                            },
                                            550: {
                                                slidesPerView: 2.2
                                            },
                                            870: {
                                                slidesPerView: 3.3
                                            },
                                            1200: {
                                                slidesPerView: 4.4
                                            }
                                        }}
                                    >
                                        {ambassadorsContent.cards.map((service, i) => {
                                            return (
                                                <SwiperSlide key={i}>
                                                    <div className='brochure__panel'>
                                                        <div className='brochure__panel__title'>
                                                            {service.title}
                                                        </div>
                                                        <div className='brochure__panel__body'>
                                                            {service.body}
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Swiper>
                                </Parallax>
                            </div>
                        </section>

                        <section className='brochure__section--white'>
                            <div className='wrap brochure__inner'>

                                {/* Title 5 */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'title5')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.title5 && 'fade-and-travel--visible'])}
                                >
                                    <h1>{ambassadorsContent.earningPotential.title}</h1>
                                </Parallax>

                                {/* Ad hoc */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'adHoc')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.adHoc && 'fade-and-travel--visible'])}
                                >
                                    <div className='brochure__centered-text'>
                                        {parse(ambassadorsContent.earningPotential.body)}
                                    </div>
                                </Parallax>
                            </div>
                        </section>

                        <section className='brochure__section--blue'>
                            <div className='wrap brochure__inner'>
                                {didSubmit &&
                                    <a id="amb-thanks" ref={tref}></a>
                                }
                                {/* Title 6 */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'title6')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.title6 && 'fade-and-travel--visible'])}
                                >
                                    <h1>{ambassadorsContent.formIntroduction.title}</h1>
                                </Parallax>

                                {/* Form */}
                                <Parallax
                                    onProgressChange={(progress) => {
                                        animateInOutHandler(progress, 0.15, 'form')
                                    }}
                                    className={getClassName(['fade-and-travel', animate.form && 'fade-and-travel--visible'])}
                                >
                                    {didSubmit &&
                                        <div className='ambassador-form ambassador-form--thanks'>
                                            <p>Thank you for your application, we will be in touch soon!</p>
                                        </div>
                                    }
                                    {!didSubmit &&
                                        <AmbassadorForm />
                                    }

                                </Parallax>

                            </div>
                        </section>

                    </div>
                </>
            }
        </>
    )
}

export default Ambassadors