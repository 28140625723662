import React from 'react'
import TitleBar from '../../components/TitleBar'
import Contract from '../../components/Contract'
import useGet from '../../hooks/useGet'
import LoadingOverlay from '../../components/LoadingOverlay'
import ErrorModal from '../../components/ErrorModal'
import {useParams} from 'react-router-dom'
import SEO from '../../components/SEO'

const ViewContract = () => {

    // Hooks
    const legId = useParams().legId
    const {loading, returnData, error} = useGet(`/api/frontend/contract-details?lo=${legId}`, 1000)

    return (
        <>
            <SEO title='View Contract' />
            <TitleBar title='View Contract' cta='Dashboard' url={`/quote-dashboard/quote/${legId}`} />

            <div className='page'>
                {loading ? <LoadingOverlay/> :
                    error ? <ErrorModal errorMessage={'Sorry, we were unable to find this contract. Please contact your SHY broker.'}/>
                        :
                        <>
                            <div className='wrap'>
                                <Contract
                                    contractData={returnData}
                                />
                            </div>
                        </>
                }
            </div>
        </>
    )
}

export default ViewContract