import {useEffect, useState} from 'react'
import AsyncSelect from 'react-select/async'
import axios from 'axios'
import getClassName from '../utilities/getClassName'
import Fieldset from './Fieldset'
import debounce from 'debounce-promise'
import envVariables from '../data/envVariables.json'

// Icons
import fromIcon from '../assets/icons/departure-time.svg'
import toIcon from '../assets/icons/arrival-time.svg'
import fromIconRed from '../assets/icons/departure-time-red.svg'
import toIconRed from '../assets/icons/arrival-time-red.svg'

const FromToInput = ({direction, value, defaultValue, label, validateForm, required, errorOnBlur, onFocus, copiedProperty}) => {

    // State
    const [error, setError] = useState('')
    const [selectedValue, setSelectedValue] = useState(null)
    const [hideMenu, setHideMenu] = useState({"menuIsOpen" : false})

    useEffect(() => {
        if (copiedProperty) {
            setSelectedValue(copiedProperty ? copiedProperty : selectedValue)
            validationHandler(copiedProperty ? copiedProperty : selectedValue)
            value(copiedProperty ? copiedProperty : selectedValue)
        }
    }, [])

    // Handle selection change
    const selectChangeHandler = selected => {
        setSelectedValue(selected)
        validationHandler(selected)
        value(selected)
    }

    // Validate input
    const validationHandler = (validateSelection) => {
        switch (true) {
            case (required && !validateSelection):
                setError(`${label} is required`)
                return
            case (required && validateSelection.length === 0):
                setError(`${label} is required`)
                return
            default:
                setError('')
                return
        }
    }

    // Load options using API call
    const debounceLoadOptions = debounce(async (inputValue) => {

        if (inputValue.length < 3) {
            setHideMenu({"menuIsOpen" : false})
        }

        if (inputValue.length >= 3) {
            setHideMenu({})

            return axios.get(`${envVariables.apiEndpoint}/api/flight/airport-info-by-name?airportName=${inputValue}`).then(response => {
                return response.data.data
            })
        }
    }, 300)

    // Set default value
    useEffect(() => {
        if (defaultValue) {
            setSelectedValue(defaultValue)
        } else {
            setSelectedValue(null)
        }
    }, [defaultValue])

    // Force validation
    useEffect(() => {
        if (validateForm) {
            validationHandler(selectedValue)
        }
    })

    const focus = () => {
        if (onFocus) {
            onFocus()
        }
    }

    const getValue = () => {
        let value

        if (copiedProperty) {
            value = copiedProperty
        }

        if (selectedValue) {
            value = selectedValue
        }
        return value
    }

    return (
        <Fieldset>
            <div style={{backgroundImage: `url(${error ? (direction === 'to') ? toIconRed : fromIconRed : (direction === 'to') ? toIcon : fromIcon})`}}
                 className={getClassName(['select-input', 'select-input--from-to', 'select-input--flight-enquiry', 'select-input--full', error && 'select-input--flight-enquiry--error'])}
            >
                <AsyncSelect
                    noOptionsMessage={() => 'No Airports Found'}
                    classNamePrefix='select-input'
                    cacheOptions
                    defaultOptions
                    value={getValue()}
                    getOptionLabel={e => e.displayName ? e.displayName : e.name ? e.name : e.iata}
                    getOptionValue={e => e.iata}
                    loadOptions={debounceLoadOptions}
                    onFocus={focus}
                    onChange={selectChangeHandler}
                    onBlur={errorOnBlur ? () => validationHandler(selectedValue) : ()=>{}}
                    placeholder={(direction === 'to') ? 'To' : 'From'}
                    {...hideMenu}
                />
            </div>
        </Fieldset>
    )
}

export default FromToInput