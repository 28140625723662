import {useEffect, useState} from 'react'
import chevronDown from '../assets/icons/chevron-down-big-white.svg'
import SelectInput from "./SelectInput"
import TextInput from "./TextInput"
import {howHeardList} from '../data/ambassadorFormListData'
import {countryList} from '../data/countryList'
import TextAreaInput from './TextAreaInput'
import Button from './Button'
import ReCAPTCHA from 'react-google-recaptcha';
import {recaptcha} from '../utilities/settings'
import parse from 'html-react-parser'
import {useGetCacheContent} from '../hooks/useGetCacheContent'

const AmbassadorForm = () => {
    const [validateForm, setValidateForm] = useState(false)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNo, setPhoneNo] = useState('')
    const [ambLoc, setAmbLoc] = useState('')
    const [howHeard, setHowHeard] = useState('')
    const [whyAmb, setWhyAmb] = useState('')
    const [captchaValid, setCaptchaValid] = useState(false)

    const currentPath = window.location.href
    const retUrl = `${currentPath}?tm=1#amb-thanks` //we might need to encode to hex/unicode for zoho's sake...e.g. https&#x3a;&#x2f;&#x2f;

    // Get and cache content
    const {content: ambassadorsContent} = useGetCacheContent('ambassadorsContent', '/api/content/ambassador', 0)

    useEffect(() => {
        const frm = document.getElementById('WebForm2249055000095946001')
        frm.setAttribute('onsubmit', '')
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.id = 'wf_anal'
        script.src = "https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=120fc61ba40e3f0f90b88a2fe8841654a9e9a2e8ca1b47d1c47a8090c5664854gid86f0abee84341b2b49d8481fe8ca5c217e95e8d9c42c171cead3a908db0d53f7gidd44407014767f2561ca996fc039fb31be317fd91ec8b948209d0fe72d87eb4e0gid14f4ec16431e0686150daa43f3210513&tw=61690b96c1d0471b638f31426f38e68aa67fb7ed6da86f32dc10ad817fe55a0a";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    function captchaClicked(value) {
        setCaptchaValid(value !== null)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setValidateForm(true)

        if (firstName && lastName && email && phoneNo && ambLoc && howHeard && whyAmb) {

            if (captchaValid) {
                let nodes = document.querySelectorAll('#wf_tr_div_120fc61ba40e3f0f90b88a2fe8841654a9e9a2e8ca1b47d1c47a8090c5664854');
                let idx = (nodes.length - 1);
                if(nodes.length > 1) {
                    while(idx > 0) {
                        document.querySelectorAll('#wf_tr_div_120fc61ba40e3f0f90b88a2fe8841654a9e9a2e8ca1b47d1c47a8090c5664854')[idx].parentNode.removeChild(document.querySelectorAll('#wf_tr_div_120fc61ba40e3f0f90b88a2fe8841654a9e9a2e8ca1b47d1c47a8090c5664854')[idx])
                        idx--;
                    }
                }
                const frm = document.getElementById('WebForm2249055000095946001')
                frm.submit()
            }
        }
    }

    return (
        <>
            <div id='crmWebToEntityForm' className='zcwf_lblLeft crmWebToEntityForm ambassador-form'>

                <form action='https://crm.zoho.com/crm/WebForm' id='WebForm2249055000095946001' name='WebForm2249055000095946001' method='POST' acceptCharset='UTF-8' onSubmit={handleSubmit}>
                    <input type='hidden' name='xnQsjsdp' value='86f0abee84341b2b49d8481fe8ca5c217e95e8d9c42c171cead3a908db0d53f7'/>
                    <input type='hidden' name='zc_gad' id='zc_gad' value=''/>
                    <input type='hidden' name='xmIwtLD' value='120fc61ba40e3f0f90b88a2fe8841654a9e9a2e8ca1b47d1c47a8090c5664854'/>
                    <input type='hidden'  name='actionType' value='Q3VzdG9tTW9kdWxlMTU='/>
                    <input type='hidden'  name='returnURL' value={retUrl} />

                    <div className='ambassador-form__intro'>
                        {parse(ambassadorsContent.formIntroduction.body)}
                    </div>

                    <div className='ambassador-form__fields'>
                        <TextInput
                            id='NAME'
                            validateForm={validateForm}
                            label='First Name*'
                            maxLength={120}
                            required
                            value={(value) => setFirstName(value)}
                        />

                        <TextInput
                            id='COBJ15CF9'
                            validateForm={validateForm}
                            label='Last Name*'
                            maxLength={255}
                            required
                            value={(value) => setLastName(value)}
                        />

                        <TextInput
                            id='Email'
                            validateForm={validateForm}
                            label='Email*'
                            required
                            validation='email'
                            maxLength={255}
                            value={(value) => setEmail(value)}
                        />

                        <TextInput
                            id='COBJ15CF8'
                            validateForm={validateForm}
                            label='Phone No.*'
                            required
                            maxLength={30}
                            value={(value) => setPhoneNo(value)}
                        />

                        <SelectInput
                            value={(value) => setAmbLoc(value)}
                            options={countryList}
                            label='Location*'
                            required
                            chevron={chevronDown}
                            validateForm={validateForm}
                            classNames={['select-input--full select-input--form']}
                        />

                        <SelectInput
                            value={(value) => setHowHeard(value)}
                            options={howHeardList}
                            label='How did you hear about us?*'
                            required
                            chevron={chevronDown}
                            validateForm={validateForm}
                            classNames={['select-input--full select-input--form']}
                        />

                        <TextInput
                            id='COBJ15CF5'
                            validateForm={validateForm}
                            label='LinkedIn'
                            maxLength={255}
                        />

                        <TextInput
                            id='COBJ15CF4'
                            validateForm={validateForm}
                            label='Instagram'
                            maxLength={255}
                        />

                        <TextAreaInput
                            id='COBJ15CF2'
                            label='What makes you the perfect Ambassador?*'
                            required
                            validateForm={validateForm}
                            value={(value) => setWhyAmb(value)}

                        />
                    </div>
                    
                    <div className='ambassador-form__captcha'>
                        <ReCAPTCHA sitekey={recaptcha.site} onChange={captchaClicked} />
                    </div>

                    <input type='hidden' value={ambLoc} id='COBJ15CF1' name='COBJ15CF1' />
                    <input type='hidden' value={howHeard} id='COBJ15CF10' name='COBJ15CF10' />

                    <div className='form-button-wrapper'>
                        <Button id='formsubmit' value='Submit' classNames={['button--tertiary']} animate={true}>Submit</Button>
                    </div>
                </form>
            </div>

        </>
    )
}

export default AmbassadorForm