import {useContext, useEffect, useRef, useState} from 'react'
import {CSSTransition} from 'react-transition-group'
import CloseCross from './CloseCross'
import ReactDOM from 'react-dom'
import {Link} from 'react-router-dom'
import useGet from '../hooks/useGet'
import shyLogo from '../assets/icons/shy-logo.svg'
import {getRestrictedLinks} from '../utilities/settings'
import getClassName from '../utilities/getClassName'
import PoweredByContext from '../store/PoweredByContext'

const Nav = ({showNav, closeNav}) => {

    // Hooks
    const transition = useRef(null)
    const {loading, error, returnData} = useGet('/api/content/header')
    const [links, setLinks] = useState(false)
    const poweredByCtx = useContext(PoweredByContext)

    const getButtonClassName = () => {
        let className = ['button', 'button--full']

        if (!poweredByCtx.isPoweredBy) {
            className.push('button--tertiary')
        }

        return className
    }

    useEffect(() => {
        if (returnData && poweredByCtx.siteReady) {
            const restrictedLinks = getRestrictedLinks(poweredByCtx.isPoweredBy)
            const filteredData = returnData.filter((link) => {
                let valid = true

                if (restrictedLinks.length > 0) {
                    restrictedLinks.forEach((restrictedLink) => {
                        if (link.url.indexOf(restrictedLink) > -1) {
                            valid = false
                        }
                    })
                }

                return valid
            })

            setLinks(filteredData)
        }
    }, [returnData, poweredByCtx.isPoweredBy, poweredByCtx.siteReady])

    const getLogo = () => {
        let image

        if (poweredByCtx?.tenant.logoImage) {
            image = poweredByCtx.tenant.logoImage
        } else {
            console.log(`Logo image not available for ${poweredByCtx.tenantID}`)
        }

        if (image) {
            return image
        }
    }

    return (
        ReactDOM.createPortal (
            <CSSTransition
            nodeRef={transition}
            in={showNav}
            timeout={500}
            unmountOnExit={true}
            classNames={{
                enterDone: 'nav-transition',
                enterActive: 'nav-transition'
            }}
        >

            <div ref={transition}>
                <div onClick={closeNav} className='nav-overlay' />

                <div className='nav'>
                    <div className='nav__inner__close-cross-container'>
                        <CloseCross onClick={closeNav} colour={poweredByCtx.isPoweredBy ? 'black' : 'white'} />
                    </div>

                    <img
                        className='nav__logo'
                        src={poweredByCtx.isPoweredBy ? getLogo() : shyLogo}
                        alt={`${poweredByCtx.isPoweredBy ? poweredByCtx?.tenant?.tenantName : 'SHY'} Logo`}
                    />

                    {links &&
                        <div className='nav__links'>
                            {links.map((link, i) => {
                                return <div className='nav__group' key={i}>
                                    <Link className='nav__link nav__link--primary' to={link.url} onClick={closeNav}>{link.name}</Link>
                                    {link.subLinks &&
                                        <>
                                            {link.subLinks.map((link, i) => {
                                                return <Link className='nav__link nav__link--secondary' to={link.url} onClick={closeNav} key={i}>{link.name}</Link>
                                            })}
                                        </>
                                    }
                                </div>
                            })}
                        </div>
                    }

                    <Link className={getClassName(getButtonClassName())} to='/' onClick={closeNav}>Request quote</Link>
                </div>

            </div>
            </CSSTransition>
            , document.body)
    )
}

export default Nav