export const gtmId = 'GTM-PMF7HLQ'

export const mapboxConfig = {
	lineColour: '000000',
    markerColour: '757575',
    width: 400,
    height: 400,
    padding: 50,
    departureLabel: 'd',
    arrivalLabel: 'a',
    accessToken: 'pk.eyJ1Ijoiam9lYWRjb2NrIiwiYSI6ImNsZDM1YzU0bzBmZWMzc29hNGE0c3hvbjYifQ.yIR--emn3ctKrGxbGRMRBQ',
    showLogo: false
}

export const recaptcha = {
    site: '6LeY76kkAAAAAJN_pPQo_649CfbSqXqzPB2uJd14'
}

export const getRestrictedLinks = (isPoweredBy) => {
    if (isPoweredBy) {
        return [
            '/ambassador',
            '/blog',
            '/careers',
            '/fly/about-us',
            '/fly/destinations',
            '/fly/industry-specialisms',
            '/fly/shy-lifestyle',
            '/fly/contact-us',
            'https://bit.ly/SHY-Aviation-Brochure'
        ]
    } else {
        return []
    }
}