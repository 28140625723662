import React, {useEffect, useRef, useState} from 'react'
import InputError from './InputError'
import Select, { components } from 'react-select'
import getClassName from '../utilities/getClassName'
import Label from './Label'
import Fieldset from './Fieldset'
import defaultChevron from '../assets/icons/chevron-down-dark-grey.svg'

function SelectInput({value, defaultValue, placeholder, label, options, search, disabled, required, validateForm, classNames, chevron, icon, menuPlacement, onFocus, inputId, copiedProperty, allowChange}){

    // State
    const [selectedOption, setSelectedOption] = useState(options.filter(option => option.value === defaultValue)[0])
    const [error, setError] = useState('')
    const [text, setText] = useState('')
    const [typedOption, setTypedOption] = useState(null)
    // const [focus, setFocus] = useState(null)

    // Add class styles
    let className = ['select-input']
    if (typeof classNames === 'object') {
        className = className.concat(classNames)
    }
    if (error) {
        className.push('select-input--error')
    }
    if (disabled) {
        className.push('select-input--disabled')
    }

    const validateHandler = (selectedOption) =>
    {
        switch(true) {
            case (required && !selectedOption):
                setError(`${label.replace('*', '')} is required`)
                return
            case (required && selectedOption.length === 0):
                setError(`${label.replace('*', '')} is required`)
                return
            default:
                setError('')
                return
        }
    }

    useEffect(() => {
        if (copiedProperty) {
            const option = copiedProperty ? copiedProperty : selectedOption
            setSelectedOption(option)
            value(option.value)
            validateHandler(option)
            setTypedOption(null)
        }
    }, [])

    // Update selected option
    const selectChangeHandler = (selected) => {
        // const option = typedOption ? typedOption : selected
        const option = selected
        setSelectedOption(option)
        value(option.value)
        validateHandler(option)
        setTypedOption(null)
    }

    // Force validation
    useEffect(() => {
        if (validateForm){
            validateHandler(selectedOption)
        }
    })

    // Set default value
    useEffect(() => {
        if (defaultValue) {
            if (!selectedOption || allowChange) {
                setSelectedOption(options.filter(option => option.value === defaultValue)[0])
            }
        }
    }, [defaultValue, options, selectedOption])

    // Custom dropdown chevron
    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <img src={chevron ? chevron : defaultChevron} alt='' />
                </components.DropdownIndicator>
            )
        )
    }

    // Focus on option select
    const Option = (props) => {
        const ref = useRef()

        useEffect(() => {
            (props.isSelected && text) && ref.current.scrollIntoView({block:'nearest'})
        }, [props])

        // if (focus && props.data.value === focus.value) {
        //     console.log("here...")
        // }

        return (
            components.Option && (
                <components.Option {...props} >
                    <div ref={ref}>{props.children}</div>
                </components.Option>
            )
        )
    };

    const isLetter = (value) => {
        return (/[a-zA-Z]/).test(value) && value.length === 1
    }

    let clearText

    useEffect(() => {
        if (text !== '') {

            const result = options.find((element) => {
                return element.label.toLowerCase().indexOf(text.toLowerCase()) === 0
            })

            if (result) {
                // setFocus(result)
                setTypedOption(result)
                setSelectedOption(result)
            }

            clearText = setTimeout(() => {
                setText('')
                // setFocus(null)
            }, 1000)
        }
    }, [text])

    const focus = () => {
        if (onFocus) {
            onFocus()
        }
    }

    return (
        <Fieldset>
            <div style={icon ? {backgroundImage: `url(${icon})`} : {}} className={getClassName(className)}>
                <Select
                    value={selectedOption}
                    components={{DropdownIndicator, Option}}
                    isDisabled={disabled}
                    isSearchable={!!search}
                    classNamePrefix='select-input'
                    onFocus={focus}
                    onChange={selectChangeHandler}
                    onKeyDown={(e) => {
                        clearTimeout(clearText)
                        if (isLetter(e.key)) {
                            setText(`${text}${e.key}`)
                        }
                    }}
                    options={options}
                    menuPlacement={menuPlacement}
                    placeholder={defaultValue ? defaultValue : placeholder}
                />
                {error && <InputError>{error}</InputError>}
                {label && <Label minimised={true}>{label}</Label>}
            </div>
        </Fieldset>

    )
}

export default SelectInput