import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import '../src/style/style.scss'
import {BrowserRouter} from 'react-router-dom'
import {UserContextProvider} from './store/UserContext'
import {AuthContextProvider} from './store/AuthContext'
import {PoweredByContextProvider} from './store/PoweredByContext'
import ScrollOnMount from './components/ScrollOnMount'
import {DevSupport} from "@react-buddy/ide-toolbox"
import {ComponentPreviews, useInitial} from "./dev"
import { ParallaxProvider } from 'react-scroll-parallax'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <PoweredByContextProvider>
            <AuthContextProvider>
                <UserContextProvider>
                    <ParallaxProvider>
                        <BrowserRouter>
                            <ScrollOnMount>
                                <DevSupport ComponentPreviews={ComponentPreviews}
                                            useInitialHook={useInitial}
                                >
                                    <App/>
                                </DevSupport>
                            </ScrollOnMount>
                        </BrowserRouter>
                    </ParallaxProvider>
                </UserContextProvider>
            </AuthContextProvider>
        </PoweredByContextProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()